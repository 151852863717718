<template>
  <div>
    <b-overlay :show="loading">
      <div class="row no-gutters">
        <div class="col-md-6 col-12 py-4 overflow-hidden my-2 px-1" v-for="c in category" :key="c" :id="`cat-widget-${c._id}`">
          <div class="category click" @click="go(c._id)">
            <h4 class="text-center">{{c.name}}</h4>
            <div class="category-img"></div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: ["id", "params"],
  data() {
    return {
      products: [],
      loading: true,
      category: []
    };
  },
  mounted() {
    console.log("Mounted category", this.params);
    this.load();
  },
  methods: {
    go(cid) {
      return this.$router.push(this.getLink('menu') + '?c=' + cid)
    },
    load() {
      let { _id } = this.$store.state.info;
      let cid = this.id;

      let query = {
        order: "createdAt",
        limit: 4,
      };
      if (cid) query.category = cid;
      if (this.params) {
        let params = this.params.split(",");
        params.forEach((p) => {
          let [key, value] = p.split("=");
          query[key] = value;
        });
      }

      const u = new URLSearchParams(query).toString();

      this.$http
        .get(`${_id}/category?${u}`)
        .then((res) => {
          this.category = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>